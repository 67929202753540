import React, { FC, useState } from "react";
import { Grid, GridRow } from "@getregistered/greg-editors";
import {
  gridLoader,
  GridLoadSuccessResponse,
} from "../../../domain/grid/resultLoader";
import { PaginatedApplicationGrid } from "../PaginatedApplicationGrid";
import { ResetButton } from "./ResetButton";
import { PrintButton } from "./PrintButton";
import railsFetch from "../../railsFetch";
import { TemplateSelect } from "./TemplateSelect";
import { TotalPrinted } from "./TotalPrinted";

export interface PaginatedNameBadgeGridProps {
  grid: Grid;
  templates: string[];
  defaultTemplate: string;
  resultsUrl: string;
  totalsUrl: string;
  printUrl: string;
  resetUrl: string;
  csvUrl: string;
  xlsxUrl: string;
  showResetAll?: boolean;
  batchSize: number;
}

export interface NameBadgeRecordsResponse extends GridLoadSuccessResponse {
  totalPrinted: number;
}

export const PaginatedNameBadgeGrid: FC<PaginatedNameBadgeGridProps> = ({
  grid,
  templates,
  defaultTemplate,
  resultsUrl,
  totalsUrl,
  printUrl,
  resetUrl,
  csvUrl,
  xlsxUrl,
  showResetAll,
  batchSize,
}) => {
  const [cacheVersion, setCacheVersion] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<GridRow[] | undefined>();
  const [template, setTemplate] = useState<string>(defaultTemplate);
  const [currentResponse, setCurrentResponse] =
    useState<NameBadgeRecordsResponse | undefined>();

  const loader = gridLoader<NameBadgeRecordsResponse>(
    resultsUrl,
    totalsUrl,
    grid.columns,
    setCurrentResponse
  );

  const printUrlWithQuery = (): string => {
    if (selectedItems?.length === 0 || selectedItems === undefined) {
      return `${printUrl}?template=${template}`;
    } else {
      const selectedItemsQuery = () =>
        selectedItems.map((i) => `id[]=${i.id}`).join("&");
      return `${printUrl}?${selectedItemsQuery()}&template=${template}`;
    }
  };

  const resetUrlWithQuery = (): string => {
    if (selectedItems?.length === 0 || selectedItems === undefined) {
      return resetUrl;
    } else {
      const selectedItemsQuery = () =>
        selectedItems.map((i) => `id[]=${i.id}`).join("&");
      return `${resetUrl}?${selectedItemsQuery()}`;
    }
  };

  const handlePrint = async () => {
    const url = printUrlWithQuery();
    const response = await railsFetch(url, { method: "POST" });
    const location = response.headers.get("Location");
    console.log("Setting cache version", cacheVersion + 1);
    setCacheVersion(cacheVersion + 1);
    window.location.href = location;
  };

  const handleReset = async () => {
    const url = resetUrlWithQuery();
    await railsFetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setCacheVersion(cacheVersion + 1);
  };

  return (
    <>
      <div>
        <header className="name-badge-actions clearfix mam">
          {currentResponse !== undefined && (
            <>
              <div className="name-badge-btn-group fr">
                <ResetButton
                  selectedItems={selectedItems}
                  onClick={handleReset}
                  showResetAll={showResetAll}
                />
                <PrintButton
                  selectedItems={selectedItems}
                  remainingItemsCount={
                    currentResponse.totalResults - currentResponse.totalPrinted
                  }
                  batchSize={batchSize}
                  onClick={handlePrint}
                />
              </div>
              <div className="name-badge-options">
                <div className="name-badge-template-select fr">
                  <TemplateSelect
                    templates={templates}
                    selectedTemplate={template}
                    onChange={setTemplate}
                  />
                </div>
                <div className="name-badge-printed-status fl">
                  <TotalPrinted
                    totalPrinted={currentResponse.totalPrinted}
                    totalItems={currentResponse.totalResults}
                  />
                </div>
              </div>
            </>
          )}
        </header>
      </div>
      <PaginatedApplicationGrid
        grid={grid}
        loader={loader}
        page={1}
        hidePerPageControl={true}
        defaultPerPage={batchSize}
        resultsCacheKey={cacheVersion}
        onSelectionChange={setSelectedItems}
        downloadLinks={[
          {
            title: "Download CSV",
            baseUrl: csvUrl,
          },
          {
            title: "Download XLSX",
            baseUrl: xlsxUrl,
          },
        ]}
      />
    </>
  );
};
